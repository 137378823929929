<template>
  <base-list-view
    :dataSource="value"
    :headers="isTemplate ? headers2 : headers"
    isNested
    :header-hidden="!isSelect"
    :hide-default-footer="!isSelect"
    :show-actions="true"
    editable
    v-on="$listeners"
    v-bind="$attrs"
    textCustomAdd="Добавить Курс"
    :notShowSelect="!isSelect"
    @customHandle="hadlerClick"
    @customAdd="showDialog = true"
    hideEdit
  >
    <popup-course
      v-if="$attrs['show-add-in-table']"
      v-model="showDialog"
      :show-add-in-table="false"
      @save="save"
      :isTemplate="isTemplate"
    ></popup-course>
  </base-list-view>
</template>
<script>
import Vue from "vue";
import { CourseStatus } from "@/data/enums";
export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    PopupCourse: () => import("./PopupCourse.vue"),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      employees: [],
      headers: [
        {
          text: "Тематика / Вид учебного материала",
          value: "Subject",
          dataType: "object",
          displayText: (e) => e?.Name,
        },
        {
          text: "Название курса",
          value: "Name",
        },
        {
          text: "Описание",
          value: "Description",
        },
        {
          text: "Период",
          value: "Period",
          dataType: "Period",
          displayText: (e) => Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: CourseStatus,
        },
      ],
      headers2: [
        {
          text: "Тематика / Вид учебного материала",
          value: "Subject",
          dataType: "object",
          displayText: (e) => e?.Name,
          width: "33%",
        },
        {
          text: "Название курса",
          value: "Name",
        },
        {
          text: "Описание",
          value: "Description",
        },
      ],
    };
  },
  async mounted() {
    // this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    save(items) {
      for (let i = 0; i < items.length; i++) {
        if (!this.value.find((e) => e.Id === items[i].Id))
          this.value.push(items[i]);
      }
      this.showDialog = false;
    },
    hadlerClick(e) {
      if (this.isTemplate) {
        this.$router.push({
          name: "KnowledgeBaseBaseCourseEdit",
          params: { objectId: e.Id },
        });
      } else {
        this.$router.push({
          name: "KnowledgeBaseCourseEdit",
          params: { objectId: e.Id },
        });
      }
    },
  },
};
</script>
